import React from 'react'
import styles from '../home.module.css'

const Parteners = ({data}) => {
    return (
        <div className={`container ${styles.parteners_section}`}>
            <h1>Nos partenaires</h1>
            <div className={styles.logos_container}>
                {data.map((item, index) => {
                    return (
                            <img key={index} src={item.logo} alt="partenaire" />
                    )
                })}
            </div>
        </div>
    )
}
export default Parteners