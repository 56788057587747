import React from 'react'
import styles from '../home.module.css'

const Activities = ({data}) => {
    const { title, description, activities } = data
    return (
        <section className={`container ${styles.activities_section}`}>
            <h1>{title}</h1>
            <p> {description}</p>
            <div id={styles.activities} className="ui stackable three columns grid">
                {
                    activities.map((item, index) => {
                        return (
                            <div key={index} className="column">
                                <div className={styles.image_container}>
                                    <img src={item.icon} size="tiny" alt={item.name} />
                                </div>
                                <h4> {item.name} </h4>
                            </div>
                        )
                    })
                }
            </div>
        </section>
    )
}
export default Activities