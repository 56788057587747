import React from 'react'
import styles from '../home.module.css'
import GoogleMaps from '../../Comon/GoogleMap';
import ContactForm from '../../Comon/ContactForm';
import { Detector } from "react-detect-offline";
import offlineImage from '../../../../static/img/oflineMap.jpg'

const ContactUs = () => {
    return (
        <section id={styles.contact} className={`container ${styles.contact_section}`}>
            <div className="ui stackable two column grid">
                <div className="column">
                    <Detector
                        render={({ online }) => {
                            return (
                                <div>
                                    {(online) ? <GoogleMaps height={500} /> :
                                        <div className={styles.offline_image_container}>
                                            <img src={offlineImage} style={{ height: 500 }} alt="offline"/>
                                        </div>
                                    }
                                </div>
                            )
                        }} />
                </div>
                <div className="column">
                    <ContactForm />
                </div>
            </div>
        </section>
    )
}
export default ContactUs