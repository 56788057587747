import React, { Component } from 'react'
import { StaticQuery, graphql, Link } from "gatsby";
import styles from '../home.module.css'
import { cutString } from '../../Comon/utils'
import LazyLoad from 'react-lazyload'
import LazyPlaceHolder from '../../Comon/LazyPlaceHolder'

class Productions extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showIndex: 0,
            showDetails: false
        }
        this.shownProductionDetails = this.shownProductionDetails.bind(this)
    }
    shownProductionDetails(index) {
        this.setState({
            showIndex: index,
            showDetails: true
        })
    }
    render() {
        const { showDetails, showIndex } = this.state
        return (
            <StaticQuery
                query={graphql`
            query HomeForwardedProjects {
              allMarkdownRemark {
                  edges{
                      node{
                          id
                          fields{
                              slug
                          }
                          frontmatter{
                              title
                              description
                              image
                          }
                      }
                  }
              }
            }`

                }
                render={data => {
                    const { edges } = data.allMarkdownRemark
                    const allProduction = edges.filter(item => {
                        return (item.node.fields.slug.includes('production'))
                    })
                    return (
                        <div className={`${styles.productions_section}`}>
                            <h1>Nos productions</h1>
                            <div onMouseLeave={() => this.shownProductionDetails(null)} className={`ui three columns stackable aligned padded grid ${styles.productions_container}`}>
                                {
                                    allProduction.map((item, index) => {
                                        return (
                                            <div id={styles.production_col} key={index} className="column">
                                                <LazyLoad
                                                    height={300}
                                                    placeholder={<LazyPlaceHolder height={300} />}>
                                                    <div onMouseOver={() => this.shownProductionDetails(index)} className={styles.content}>
                                                        <div>
                                                            <img src={item.node.frontmatter.image} alt="production" />
                                                        </div>
                                                        {(showDetails && showIndex === index) && < div className={styles.production_info}>
                                                            <h3>{item.node.frontmatter.title}</h3>
                                                            <p>{cutString(item.node.frontmatter.description, 100)}</p>
                                                            <Link to={item.node.fields.slug}>Details</Link>
                                                        </div>}
                                                    </div>
                                                </LazyLoad>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                }} />
        )
    }
}
export default Productions
