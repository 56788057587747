import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import TemplateWrapper from '../components/Layout'
import HomePageComponent from '../components/Home'
import Helmet from "react-helmet";

export const ProductPageTemplate = ({ seo, header, activities, presentation, partners }) => {
  return (
    <TemplateWrapper>
      <Helmet
        title={seo.seo_title}
        meta={[

          {
            name: "description",
            content: seo.seo_metta_desc
          }
        ]}
      />
      <HomePageComponent
        header={header}
        activities={activities}
        presentation={presentation}
        partners={partners}
      />

    </TemplateWrapper>
  )
}
ProductPageTemplate.propTypes = {
  header: PropTypes.shape({
    title: PropTypes.string,
    sub_title: PropTypes.string,
  }),
}

const ProductPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  console.log(data)
  return (
    <ProductPageTemplate
      seo={frontmatter.seo}
      header={frontmatter.header}
      activities={frontmatter.activities}
      presentation={frontmatter.presentation}
      partners={frontmatter.partners}
    />
  )
}

ProductPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ProductPage

export const productPageQuery = graphql`
  query ProductPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seo{
          seo_title
          seo_metta_desc
        }
        header {
          title
          image
        }
        activities{
          title
          description
          activities{
            name
            icon
          }
        }
        presentation{
          title
          present
          image
        }
        partners{
          logo
        }
      }
    }
  }
`
