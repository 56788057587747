import React from 'react'
import styles from '../home.module.css'
import { Link } from 'gatsby';
import { Button } from 'semantic-ui-react';

const JoinUs = () => {
    return (
        <section className={styles.joinus_section}>
            <div className="container">
                <h1>Faire partie de l’équipe AKKA Media</h1>
                <p>On sait depuis longtemps que travailler avec du texte lisible et contenant du sens </p>
                <Link to='#'><Button color="red">Postulez</Button></Link>
            </div>
        </section>
    )
}
export default JoinUs