import React from "react";
import styles from "./home.module.css";
import { Button } from 'semantic-ui-react'
import { Link } from "gatsby";
import Activities from "./helpers/activities";
import Presentation from "./helpers/presentation";
import JoinUs from "./helpers/JoinUs";
import Productions from "./helpers/productions";
import Parteners from "./helpers/parteners";
import ContactUs from "./helpers/ContactUs";

const HomePageComponent = ({ header, activities, presentation, partners }) => {
  return (
    <div>
      <section className={`header-parallax ${styles.hero}`} style={{ backgroundImage: `url(${header.image})` }}>
        <h1>{header.title}</h1>
        <Link to="#"><Button color="red">Contactez nous</Button></Link>
      </section>
      <Activities data={activities} />
      <Productions />
      <Presentation data={presentation} />
      <JoinUs />
      <Parteners data={partners} />
      <ContactUs />
    </div>
  );
};
export default HomePageComponent;
